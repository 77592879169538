import React from 'react'
import PortfolioImage from './pImages'
import ReactPlayer from 'react-player'

function PortfolioMain(props)
{
    return (
        <div className="portfolio-images-main">
            {
                props.cards.map((item, index) => item.type === "video" ?
                    <div style={{ width: `${item.width}px`, margin: "5px 4px" }}>
                        <ReactPlayer
                            height="100%"
                            width="100%"
                            playing={item.playing}
                            loop={true}
                            muted={true}
                            controls={true}
                            url={item.url}
                        />
                    </div>
                    :
                    <div style={{ width: `${item.width}px`, margin: "5px 4px" }}>
                        <PortfolioImage image={item.image} />
                    </div>)
            }
        </div>
    )
}


export default PortfolioMain