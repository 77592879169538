import React from "react"
import Layout from "../components/defaults/layout"
import SEO from "../components/defaults/seo"
import Cards from "../components/portfolio/portfolioCards"
import CvSection from "../components/works/cvSection"
import "../style/works/works.scss"
import PortfolioMain from "../components/portfolio/Portfolio"

function Portfolio() {
  return (
    <Layout>
      <SEO title="Compercial Portfolios" keywords={[`Navasardi`, `Arsen Navasardi`, `Navasardi Art Studio`, `Navasardi Art Studio Portfolio`, `Art Studio Yerevan`, `Art Studio Armenia`, `Compercial Portfolios`, `Navasardi Art Studio Compercial Portfolios`, `Navasardi Portfolios`,]} />
      <div className="works-main">
        <CvSection />
        {/* <Slider page="portfolio" cards={Cards} /> */}
        <PortfolioMain cards={Cards} />
      </div>
    </Layout>
  )
}
export default Portfolio
