const Cards = [

    {
        width: 743,
        type: "video",
        url: "https://res.cloudinary.com/dmgjq19hi/video/upload/v1567268169/christmas_xjbqfx.mp4",
        name: "christmas",
        playing: true
    },
    {
        width: 743,
        type: "video",
        url: "https://res.cloudinary.com/dmgjq19hi/video/upload/v1567268169/wedding_pcphh2.mp4",
        name: "wedding",
        playing: false
    },
    {
        width: 420,
        image: "p1",
    },
    {
        width: 420,
        image: "p2",
    },
    {
        width: 315,
        image: "p3",
    },
    {
        width: 382,
        image: "p4",
    },
    // {
    //     width: 320,
    //     image: "p5",
    // },
    {
        width: 531,
        image: "p6",
    },
    {
        width: 488,
        image: "p7",
    },
    {
        width: 420,
        image: "p8",
    },
    {
        width: 362,
        image: "p9",
    },

    {
        width: 306,
        image: "p10",
    },
    {
        width: 399,
        image: "p11",
    },
    {
        width: 560,
        image: "p12",
    },
    {
        width: 336,
        image: "p13",
    },
    {
        width: 315,
        image: "p14",
    },
    {
        width: 420,
        image: "p15",
    },
    {
        width: 333,
        image: "p16",
    },
    {
        width: 366,
        image: "p17",
    },
    {
        width: 416,
        image: "p18",
    },

    {
        width: 280,
        image: "p19",
    },
    {
        width: 280,
        image: "p20",
    },
    {
        width: 420,
        image: "p21",
    },
    {
        width: 420,
        image: "p22",
    },
    // {
    //     width: 400,
    //     image: "p23",
    // },
    // {
    //     width: 499,
    //     image: "p24",
    // },
    // {
    //     width: 400,
    //     image: "p25",
    // },
    // {
    //     width: 400,
    //     image: "p26",
    // },
    // {
    //     width: 324,
    //     image: "p27",
    // },
    // {
    //     width: 324,
    //     image: "p28",
    // },

]

export default Cards
