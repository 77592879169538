import React, { useState } from 'react';
import Modal from "../defaults/Modal";
import { Document, Page } from 'react-pdf';
import Pdf from "../download/cvP.pdf"


function CvSection() {
    const [open, setOpen] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumer] = useState(1)
    function toggleModal() {
        setOpen(!open)
    }

    React.useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }, [open])

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }
    return (
        <>
            <div className="cv-section">
                <div className="flex-between">
                    <div className="cv-left">
                        <span>Having shaped a reliable team of professional engineers, photographers, digital artists and programmers enables me to broaden range of creative services and take commercial inquiries for projects of all sizes and complexity. <br /><br />Below you can find several examples of event decorations, retail window design, wall mural painting, photo shoot coordination and design.  </span>
                        <button onClick={undefined}> <a href={Pdf} target="_blank">about me/cv</a></button>
                        {/* {
                            open && <Modal onClose={setOpen}>
                                <div>
                                    <Document
                                        className="pdf-doc"
                                        height="500px"
                                        width={400}
                                        file={Pdf}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        <Page pageNumber={pageNumber} />
                                    </Document>
                                    <p>Page {pageNumber} of {numPages}</p>
                                    <button onClick={e => setPageNumer(pageNumber === 1 ? 2 : 1)}>page {pageNumber === 1 ? 2 : 1}</button>
                                </div>
                            </Modal>
                        } */}
                    </div>
                    <h2>
                        Portfolio
                </h2>
                </div>
            </div>
        </>
    );
}

export default CvSection;