import React from 'react'
import Animated from "../animated"
import "../../style/modal.scss"

function Modal(props) {
    function onClose() {
        props.onClose();
    }

    return (
        <Animated
            from={{ opacity: 0, transform: "scale(0.6)" }}
            to={{ opacity: 1, transform: "scale(1)" }}
            delay={300}
        >
            {
                style => <div onClick={onClose} className="modal-main">
                    <div style={style} className="modal">
                        <div onClick={e => e.stopPropagation()} className="modal-body">
                            <button className="close-btn" onClick={onClose}><i className="icon-close"></i></button>
                            {props.children}
                        </div>
                    </div>
                </div>
            }
        </Animated>
    )
}

export default Modal;